<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <img
          src="@/assets/images/gif/progress.gif"
          class="img-fluid"
          alt="logo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';

export default {
  name: 'CallbackMercadoPago',
  mixins: [errorDialog],
  data () {
    return {};
  },
  mounted () {
    this.getMercadoPagoResponse();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    getMercadoPagoResponse () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code');
      this.backendCallback(code);
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    async backendCallback (code) {
      const data = {
        client_secret: process.env.VUE_APP_MP_APP_MARKETPLACE_ACCESS_TOKEN,
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: process.env.VUE_APP_MP_REDIRECT_URL
      };
      const headers = {
        headers: {
          prsk_authorization: '6329f0b0470d8b3ab41475962974b26a'
        }
      };
      const url = `https://gwzk525bz8.execute-api.us-east-1.amazonaws.com/default/prosk-mercado-pago-auth`;
      axios
        .post(url, data, headers)
        .then((response) => {
          const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}`;
          let dataResponse = response.data;
          if (dataResponse.hasOwnProperty('data')) {
            dataResponse = dataResponse.data;
          }
          const data = {
            mp_access_token: dataResponse.access_token,
            mp_refresh_token: dataResponse.refresh_token,
            mp_public_key: dataResponse.public_key,
            mp_user_id: dataResponse.user_id.toString()
          };
          axios
            .patch(url, data)
            .then(() => {
              this.updateUser(this.currentUser.id);
              this.updatePercentage(this.currentUser.id);
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        })
        .catch((error) => {
          this.showError(error);
        });
      await this.$router.push({ name: 'social.user-profile' });
    }
  }
};
</script>
